<!-- Recurrence Pattern -->
<main class="d-flex flex-column mb-4">
  <label class="mb-3">
    <span class="required">* </span>
    {{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_PATTERN' | translate }}
  </label>
  <div id="recurrences" class="d-flex">
    <div *ngFor="let recurrence of recurrences" class="pretty p-default p-round">
      <input type="radio" [formControl]="recurrencePattern" [value]="recurrence" />
      <div class="state p-primary-o">
        <label>{{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_PATTERN_' + recurrence | translate }}</label>
      </div>
    </div>
  </div>
</main>

<form [formGroup]="recurrenceRange" class="container-grid pb-3">
  <!-- Label -->
  <label class="mb-2">
    <span class="required">* </span>
    {{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_RANGE' | translate }}
  </label>

  <!-- Start Date -->
  <section class="d-flex align-items-start">
    <div class="d-flex align-items-end">
      <label class="mr-3">{{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_RANGE_START' | translate }}</label>
      <input
        [owlDateTime]="dtStart"
        [selectMode]="'single'"
        [min]="minStart"
        [max]="maxStartDate"
        class="shadow-input"
        formControlName="startDate"
      />
      <input
        class="form-control generalInput"
        [value]="formattedDates(controlRecurrenceStartDate.value)"
        [owlDateTimeTrigger]="dtStart"
        [attr.disabled]="controlRecurrenceStartDate.disabled || null"
        readonly
      />
      <owl-date-time [pickerType]="'calendar'" #dtStart></owl-date-time>
    </div>
  </section>

  <!-- End Date Options -->
  <section class="gapped">
    <!-- End By -->
    <div class="d-flex align-items-center">
      <div class="pretty p-default p-round">
        <input type="radio" formControlName="rangeOption" id="end-by" [value]="endOptions.ENDBY" />
        <div class="state p-primary-o">
          <label>{{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_RANGE_END_BY' | translate }}</label>
        </div>
      </div>
      <input [owlDateTime]="dtEnd" [min]="minEnd" class="shadow-input" formControlName="endDate" />
      <input
        [owlDateTimeTrigger]="dtEnd"
        class="form-control generalInput"
        [value]="formattedDates(controlRecurrenceEndDate.value || minEnd)"
        (focus)="!control.disabled && recurrenceRange.get('rangeOption').patchValue(endOptions.ENDBY)"
        readonly
      />
      <owl-date-time [pickerType]="'calendar'" #dtEnd></owl-date-time>
    </div>

    <!-- End After X Occurences -->
    <div class="d-flex align-items-center">
      <div class="pretty p-default p-round">
        <input type="radio" formControlName="rangeOption" id="end-after" [value]="endOptions.ENDAFTER" />
        <div class="state p-primary-o">
          <label>{{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_RANGE_END_AFTER' | translate }}</label>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <input
          class="generalInput text-center mr-1"
          type="number"
          step="1"
          min="2"
          (keydown)="onKeyDown($event)"
          (focus)="!control.disabled && recurrenceRange.get('rangeOption').patchValue(endOptions.ENDAFTER)"
          formControlName="rangeOccurrences"
        />
        <span>{{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_RANGE_OCCURRENCES' | translate }}</span>
      </div>
    </div>

    <!-- No End Date -->
    <div *ngIf="!hideNoEndDate" class="pretty p-default p-round">
      <input type="radio" formControlName="rangeOption" id="no-end" [value]="endOptions.NOEND" />
      <div class="state p-primary-o">
        <label>{{ 'DONATE_RECURRING_CONTRIBUTION_LABELS_RANGE_NO_END' | translate }}</label>
      </div>
    </div>

    <!-- Validation Errors -->
    <p *ngIf="rangeEndErrors?.required" class="small text-danger m-0">
      {{ 'FORM_VALIDATORS_DATE_REQUIRED' | translate }}
    </p>
    <p *ngIf="rangeEndErrors?.notZero" class="small text-danger m-0">
      {{ 'FORM_VALIDATORS_RECURRING_VALUE_POSITIVE' | translate }}
    </p>
    <p *ngIf="rangeEndErrors?.noSelection" class="small text-danger m-0">
      {{ 'FORM_VALIDATORS_RECURRING_OPTION_REQUIRED' | translate }}
    </p>
  </section>
</form>
