import { isEqual } from 'lodash-es';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, take, takeUntil, tap } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { TransactionService } from '../../../_core/api/transaction.service';
import { INPUT_TYPE } from '../../../_core/contants/Contribute';
import { getRangePatterns } from '../../../_core/contants/rangePatterns';
import { SERVER_ERROR } from '../../../_core/contants/ServerError';
import { CustomValidators } from '../../../_core/helpers/custom-validators.helpers';
import SharedMethodsHelpers from '../../../_core/helpers/shared-methods.helpers';
import { CustomControl } from '../../../_core/models/CustomForms.model';
import { GlobalConfigService } from '../../../_core/services/global-config.service';
import { ModalsService } from '../../../_core/services/modals.service';
import { RecurringTransfer } from '../../../pages/transaction/manage-recurring/models';
import { BalanceCurrency } from '../../../pages/transaction/transaction-form/transaction-form.const';
import { RECURRENCE_RANGE_END } from '../../recurring-form/recurring-form.models';
import { createRangeEndValidator } from '../../recurring-form/recurring-form.validator';

@Component({
  selector: 'app-edit-transaction',
  templateUrl: './edit-transaction.component.html',
  styleUrls: ['./edit-transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTransactionComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  inputTypes = INPUT_TYPE;

  startDateDisabled: boolean;
  params = this.modalsService.params;
  modalLoading = true;
  grantWarning = false;
  showSummary = false;
  formTouched = false;

  initialValues = {
    recurrencePattern: this.params.transaction.recurrencePattern,
    startDate: this.params.transaction.startDate,
    endDate: this.params.transaction.endDate,
    amount: this.params.transaction.amountInCents / 100,
  };

  updatedValues = {
    recurrencePattern: null,
    startDate: null,
    endDate: null,
    amount: null,
  };

  dateFormat$ = this.globalConfigService.globalConfig$.pipe(map(({ dateFormat }) => dateFormat));

  editTransactionForm = new UntypedFormGroup(
    {
      recurrence: new UntypedFormGroup({
        recurrencePattern: new CustomControl({
          value: this.params.transaction.recurrencePattern,
        }),
        recurrenceRange: new UntypedFormGroup(
          {
            rangeOption: new CustomControl({
              value: RECURRENCE_RANGE_END.ENDBY,
            }),
            startDate: new CustomControl({
              value: new Date(this.params.transaction.startDate),
            }),
            endDate: new CustomControl({
              value: new Date(this.params.transaction.endDate),
            }),
            rangeOccurrences: new CustomControl({
              value: 2,
            }),
          },
          { validators: [createRangeEndValidator()] }
        ),
      }),
      amount: new CustomControl({
        value: this.params.transaction.amountInCents / 100,
        label: 'DONATE_CONTRIBUTION_LABELS_AMOUNT',
        validation: [
          ['required', 'FORM_VALIDATORS_AMOUNT_REQUIRED'],
          ['positiveNumber', 'FORM_VALIDATORS_AMOUNT_POSITIVE'],
          ['maxAmountAllowed', 'FORM_VALIDATORS_AMOUNT_EXCEEDS_BALANCE'],
          ['maxValue', 'FORM_VALIDATORS_AMOUNT_MAX_EXCEEDED'],
        ],
        max: null,
        helperData: {
          minValue: null,
          isVerifying: false,
          showError: false,
        },
      }),
      currency: new CustomControl({
        value: this.params.transaction.currencySymbol,
        hasAuxControl: true,
      }),
    },
    { validators: [this.createRangeValidator()] }
  );

  get amount(): CustomControl {
    return this.editTransactionForm.controls.amount as CustomControl;
  }

  get currency(): CustomControl {
    return this.editTransactionForm.controls.currency as CustomControl;
  }

  constructor(
    private globalConfigService: GlobalConfigService,
    private modalsService: ModalsService,
    private transactionService: TransactionService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.showSummary = false;
    this.patchCurrency();
    this.setFundAvailableAmount();
    this.setFormListeners();
    this.disableStartDate();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  disableStartDate(): void {
    const currentDate = new Date();
    const formStartDate = this.editTransactionForm.get('recurrence.recurrenceRange.startDate').value;

    this.startDateDisabled = moment(formStartDate).isSameOrBefore(moment(currentDate), 'date');
    this.startDateDisabled && this.editTransactionForm.get('recurrence.recurrenceRange.startDate').disable();
  }

  setFundAvailableAmount(): void {
    const transaction: RecurringTransfer = this.params.transaction;

    this.transactionService
      .getCurrencies(transaction.senderCseg5)
      .pipe(
        tap(() => (this.modalLoading = true)),
        map((currencies: BalanceCurrency[]) => currencies.find((currency) => +currency.id === transaction.currency)),
        map(({ availableAmountFundCash }) => availableAmountFundCash)
      )
      .subscribe((availableAmount) => {
        this.setAvailableAmount(availableAmount);
        this.modalLoading = false;
        this.cdr.markForCheck();
      });
  }

  createRangeValidator(): ValidatorFn {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      let rangeError: { [key: string]: boolean } | null = null;

      const amount = form.get('amount');
      const { recurrencePattern, recurrenceRange } = form.get('recurrence').value;
      const { endDate, rangeOccurrences, rangeOption } = recurrenceRange;
      const startDate = new Date(this.params.transaction.startDate);

      this.grantWarning = false;

      if (rangeOption === RECURRENCE_RANGE_END.NOEND) rangeError = null;

      const availableValue = amount['helperData'].availableValue;

      if (rangeOption === RECURRENCE_RANGE_END.ENDAFTER) {
        rangeError = amount.value * rangeOccurrences > availableValue ? { rangeError: true } : null;
      }

      if (rangeOption === RECURRENCE_RANGE_END.ENDBY) {
        rangeError =
          amount.value * getRangePatterns(startDate, endDate)[recurrencePattern] > availableValue
            ? { rangeError: true }
            : null;
      }

      if (rangeError || rangeOption === RECURRENCE_RANGE_END.NOEND) {
        this.grantWarning = true;
        return null;
      }

      return rangeError;
    };
  }

  setFormListeners(): void {
    this.amount.valueChanges.pipe(debounceTime(300), takeUntil(this._destroy$)).subscribe(() => {
      if (!this.params.isGrant) {
        this.checkMinimumAmount();
      }
      if (this.amount.value < 1) this.amount.patchValue(0, { emitEvent: false });
      this.cdr.markForCheck();
    });

    // Workaround for form not updating .pristine/.dirty properties
    this.editTransactionForm.valueChanges
      .pipe(
        skip(1),
        distinctUntilChanged((x, y) => !isEqual(x, y)),
        take(1)
      )
      .subscribe(() => {
        this.formTouched = true;
        this.cdr.markForCheck();
      });
  }

  checkMinimumAmount(): void {
    if (!this.amount.value) return;

    this.amount.helperData.showError = false;
    this.amount.helperData.isVerifying = true;

    const payload = { amount: this.amount.value, currency: 2 };

    this.transactionService.calculateGrant(payload).subscribe({
      next: () => {
        this.amount.helperData.isVerifying = false;
        this.cdr.markForCheck();
      },
      error: (error) => {
        if (error.status === 409) {
          if (error.error.message === SERVER_ERROR.INVALID_CURRENCY) {
            this.translateService.get('TOASTER_INVALID_CURRENCY').subscribe((text: string) => alert(text));
          }
          this.amount.helperData.minValue = error.error.minimumGrantAmount;
          this.amount.helperData.isVerifying = false;
          this.amount.helperData.showError = true;
        }
        this.cdr.markForCheck();
      },
    });
  }

  setAvailableAmount(amount: number): void {
    this.amount.helperData.availableValue = amount ? SharedMethodsHelpers.computeAmountToDisplay(amount) : 0;
    this.amount.setValidators([
      CustomValidators.maxAmountAllowed(this.amount.helperData.availableValue),
      CustomValidators.required,
      CustomValidators.positiveNumber,
    ]);
  }

  onNextOrSubmit(): void {
    if (this.showSummary) {
      const payload = {
        id: this.params.transaction.id,
        recurrencePattern: this.updatedValues.recurrencePattern,
        startDate: this.updatedValues.startDate,
        endDate: this.updatedValues.endDate,
        amountInCents: this.updatedValues.amount * 100,
        currency: this.params.transaction.currency,
        currencySymbol: this.params.transaction.currencySymbol,
      };

      this.modalsService.emitResponse(payload);
      this.closeModal();
    } else {
      this.showSummary = true;
      this.updatedValues = {
        ...this.updatedValues,
        amount: this.editTransactionForm.value.amount,
        startDate: moment(this.editTransactionForm.value.recurrence.recurrenceRange.startDate).valueOf(),
        endDate: moment(this.editTransactionForm.value.recurrence.recurrenceRange.endDate).valueOf(),
        recurrencePattern: this.editTransactionForm.value.recurrence.recurrencePattern,
      };
    }
  }

  patchCurrency(): void {
    this.currency.patchValue(this.params.transaction.currencySymbol);
    this.currency.auxControl.patchValue(this.params.transaction.currencySymbol);
    this.currency.auxControl.disable();
  }

  onCancel(): void {
    if (this.showSummary) {
      this.showSummary = false;
    } else {
      this.closeModal();
    }
  }

  closeModal(): void {
    this.modalsService.closeModal();
  }
}
